/* * @Author: liuzhixiang * @Date: 2021-02-06 14:49:09 * @Last Modified by:
liuzhixiang * @Last Modified time: 2021-03-08 11:24:56 */
<template>
  <div class="dev">
    <div class="container">
      <div class="content">
        <div class="title">404 很抱歉，您查看的页面找不到了！</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  created() {},
  mounted() {},
  methods: {},
};
</script>

<style lang="less" scoped>
.dev {
  // background-color: #fff;
  // font-size: 14px;
  // min-height: calc(100vh - 200px);
  // margin-top: -30px;
  padding: 0;
  width: 100%;
  height: 100%;
  color: #b0bec5;
  display: table;
  font-weight: 100;
  background-color: #f5f5f5;
  .container {
    text-align: center;
    display: table-cell;
    vertical-align: middle;
    padding-top:calc((100vh - 200px)/2);
    .content {
      text-align: center;
      display: inline-block;
      .title {
        font-size: 42px;
        margin-bottom: 40px;
      }
    }
  }
}
</style>
